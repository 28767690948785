@import '../colors.scss';

.footer {
  padding: 1rem;
  margin-bottom: 0;
  background-color: $reddarken-3;
  height: 7.5rem;
  color: $white;
  font-size: 1.1rem;
  justify-content: space-between
}

.footer-column {
  flex-direction: column
}

.footer-logos {
  margin-top:2em;
  margin-left: 5em;
  @media(max-width: 650px){
    margin-left: 0;
  }

  a {
    text-decoration: none;
  }

  img {
    margin: 0.5rem;
    margin-bottom: 0;
    width: 2.5em;
    height: 2.5em;
  }
}

.footer-wrapper {
  text-align: center;
  margin-right: 5em;
  @media(max-width: 650px){
    margin-right: 0;
  }
}
.footer-cm42-logo {
  margin-top: 0.5rem;
  width: 5rem;
  height: 5rem;
}

.footer-copyright {
  margin-top: 1rem;
  font-size: 0.8rem;
}
