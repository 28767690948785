@import '../colors.scss';

.feature-section-small {
  @media (min-width: 800px) {
    display: none;
  }
}

.feature-column {
  width: 50%;
  @media(max-width: 800px) {
    width: 100%;
    align-items: center
  }
}

.features-subtitle {
  color:$reddarken-2;
  font-size: 1.8rem;
  text-align: center;
}


.report-subtitle {
  width: 100%;
}

.features-list {
  font-size: 1.3rem;
  line-height: 3rem;

  @media (max-width: 800px) {
    list-style-type: none;
    text-align: center;
    padding-left: 0
  }
}

.backlog-list {
  margin-left: 8rem;
  @media (max-width: 800px) {
    margin-left: 0
  }
}

.report-list {
  margin-left: 10rem;
  @media (max-width: 800px) {
    margin-left: 0
  }
}
