@import '../colors.scss';

.banner-secondary {
  background: url('../images/cowork-dark.png') no-repeat center / cover;
  height: 100%;
  background-attachment: fixed;

  h1 {
    font-size: 5rem;
    color: $white;
    text-align: center;
    padding-top: 15rem;
    font-weight: 600;
    letter-spacing: 0.5rem;
    text-transform: uppercase;

    @media (min-width: 601px) and (max-width: 1400px) {
      padding-top: 4rem;
      font-size: 4rem;
    }

    @media (max-width: 600px) {
      padding-top: 4rem;
      font-size: 2rem;
    }
  }

  .banner-subtitle {
    width: 60%;
    margin: 0 auto;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  p {
    font-size: 3vh;
    color: $white;
    text-align: center;
    padding: 0 2rem;
    font-weight: 200;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    line-height: 4.5rem;

    @media (max-width: 600px) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

@-moz-document url-prefix() {
  .banner-secondary {
    max-height: 50rem;
  }
}
