.integration {
  text-align: center;
  font-size: 1.3rem;
  margin: 4rem 0 4rem 0;
  
  h2 {
    margin-bottom: 2rem;
  }

  img {
    margin-top: 2rem;
    height: 6.25rem;
    width: 6.25rem;
    margin: 0 1rem 0 1rem;

    @media(max-width: 600px) {
      height: 4rem;
      width: 4rem;
    }
  }
}
