$white: #ffffff;
$whitedarken-1: #f5f5f8;
$whitedarken-2: #ccc;
$black: #000000;
$blacklight-1: #333333;
$blacklight-2:rgba(0,0,0,0.1);
$orangedark-1:#C5322B; 
$orangedark-2:#DB362F;
$reddarken-2: #d0011b;
$reddarken-3: #AE0606;
$reddarken-4: #8E1010;
$reddarken-5:  rgba(83, 6, 6, 0.30);
$reddarken-6: rgba(31, 1, 1, 0.30);
$grey: #BAB8B8;
$greydarken-1:rgba(255,255,255,0.3);
$greydarken-3: #585858;
