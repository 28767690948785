@import '../colors.scss';

body {
  margin: 0px;
}

h1, h2, h3, a, p, li, span {
  font-family: 'Open Sans', sans-serif;
}

.flex {
  display: flex;
}

.flex-row {
  width: 100%;
  flex-direction: row;

  @media(max-width: 800px){
    flex-direction: column;
  }
}

.flex-column {
  width: 100%;
  flex-direction: column;
}

.flex-container {
  margin: 0 0 2rem 0;
  padding: 0;
}

hr.line {
  border: 0;
  height: 0.1rem;
  background: $blacklight-1;
  width: 30%;
  background-image: linear-gradient(to right, $whitedarken-2, $blacklight-1, $whitedarken-2);
  margin-bottom: 4rem;
}

.section-heading {
  border: 0;
  height: 0.1rem;
  background: $grey;
  width: 40%;
  background-image: linear-gradient(to right, $whitedarken-2, $blacklight-1, $whitedarken-2);
  margin-bottom: 4rem;
}

.section-title {
  text-align: center;
  font-size: 3rem;
}

.helper-img-wrapper {
  width: 50%;
  
  @media(max-width: 800px) {
    width: 100%;
  }
}


.helper-shadow-image {
  width: 90%;
  box-shadow: 5px 5px 10px grey;
  display: block;
  margin: 0 auto;
  margin-bottom: 2rem;
}


.helper-shadow-cards {
  width: 90%;
  box-shadow: 2px 2px 4px grey;
  margin: 0 auto;
  margin-bottom: 2rem;
}
