@import '../colors.scss';

.wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    width: 30%;
    margin-bottom: 2rem;

    @media(max-width: 650px){
      width: 50%;
    }
  }
}

.more-feature-section {
  background-color: $whitedarken-1;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.more-feature-list {
  list-style-type: none;
  text-align: center;
  font-size: 1.5rem;
  img {
    height: 8rem;
    width: 8rem;
    margin: 1rem 0 1rem 0;
    
    @media(max-width: 600px) {
      height: 4.5rem;
      width: 4.5rem;
    }
  }

  p {
    font-size: 1.2rem;
    padding: 0 1rem;
    
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }

  .mentions {
    font-size: 7.5rem;

    @media (max-width: 600px) {
      font-size: 5.2rem;
    }
  }
}
